@import "import/components/_modal.scss";

#search-section {
  ul,
  ol {
    list-style: none;
  }
}

[v-cloak] {
  display: none;
}

#search-more-options {
  position: relative;
  z-index: 1;
  #disable-more {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    top: 0;
    left: 0;
  }
}

#scrollbtn {
  position: fixed;
  text-align: center;
  width: 100%;
  bottom: 1em;
  left: 0;
  z-index: 99999;
  > div {
    max-width: 500px;
    display: table;
    margin: 0 auto;
    background-color: white;
    padding: 0.5em 2em;
    border-radius: 2em;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
  }
  a {
    color: #a8212f;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    span:last-child {
      display: block;
      &:after {
        content: "";
        margin-left: 0.5em;
        width: 24px;
        height: 24px;
        background-size: contain;
        display: block;
        background-image: url(../images/arrow.png);
      }
    }
  }
}
